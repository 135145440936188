import Icon, { IconIds } from '@/components/atoms/legacy/Icon';
import Input, { InputProps, InputTypes } from '@/components/atoms/legacy/Input';
import Label from '@/components/atoms/legacy/Label';
import DropdownMenu, {
  DropdownMenuOptionType,
} from '@/components/molecules/DropdownMenu';
import { formInputStyles } from '@/lib/constants';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import React, { ReactElement } from 'react';

export interface FormInputProps extends InputProps {
  label?: string | ReactElement;
  search?: boolean;
  invalid?: boolean;
  errorMessage?: string;
  inputStyle?: string;
  autocompleteOptions?: DropdownMenuOptionType[];
  setAutocompleteOptions?: (val: DropdownMenuOptionType[]) => void;
  onKeyDown?: (evt: React.KeyboardEvent<HTMLInputElement>) => void;
  submitButton?: React.ReactElement;
  icon?: React.ReactElement;
  handleIconClick?: () => void;
  iconProps?: object;
  variant?: 'primary';
  /** Requires the `label` prop to be set to see any changes.
   * If `label` is set and if `required` is set to `true`,
   * show a red asterisk, indicating that this field is required. */
  required?: boolean;
  labelStyle?: string;
  step?: number;
  min?: string;
  max?: string;
  float?: number;
  prepend?: string;
  append?: string;
  id?: string;
}

const FormInput: React.FC<FormInputProps> = ({
  className = '',
  label = '',
  search = false,
  invalid = false,
  errorMessage,
  autocompleteOptions = [],
  setAutocompleteOptions = () => {},
  onChange,
  onPaste,
  submitButton,
  icon,
  handleIconClick,
  iconProps,
  inputStyle,
  variant,
  required = false,
  labelStyle,
  step,
  prepend,
  append,
  float,
  id,
  ...props
}) => {
  const labelRef = React.useRef<HTMLElement>(null);
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (autocompleteOptions.length > 0) {
      const clickOutside = (evt: MouseEvent) => {
        if (!evt.target || !labelRef.current) {
          return;
        }
        if (!labelRef.current.contains(evt.target as Node)) {
          setAutocompleteOptions([]);
        }
      };
      document.addEventListener('click', clickOutside);
      return () => {
        document.removeEventListener('click', clickOutside);
      };
    }
    return () => {};
  }, [autocompleteOptions, setAutocompleteOptions]);

  return (
    <section
      className={`form-input flex flex-grow ${search ? 'form-input-search' : ''} ${
        variant === 'primary'
          ? ''
          : '[&>div>input]:text-body3 [&>div]:rounded-small [&>div]:border-neutral-300 [&>div]:bg-white [&>label]:text-body2Regular [&>label]:text-neutral-800 [&>span]:text-body3'
      } ${className}`}
      ref={labelRef}
    >
      <Label className="relative flex w-full flex-col">
        {label && (
          <span
            className={`mb-s block ${labelStyle} ${
              variant === 'primary' ? 'text-body2Regular text-neutral-800' : ''
            }`}
          >
            {label}
            {!!required && <span className="text-red-medium"> *</span>}
          </span>
        )}

        <div className="relative flex">
          {(prepend || append) && (
            <div
              className={`absolute z-10 ${
                prepend ? 'left-[13px]' : 'right-[14px]'
              } pointer-events-none h-full border-none bg-transparent`}
            >
              <span
                className={`flex h-full items-center text-body3 text-neutral-800`}
              >
                {prepend && <>{prepend}</>}
                <span className="text-transparent">{props.value}</span>
                {append && <>{append}</>}
              </span>
            </div>
          )}

          <Input
            className={`${formInputStyles} ${inputStyle} ${
              invalid ? 'error' : ''
            } ${prepend ? 'pl-[21px]' : ''}`}
            onChange={onChange}
            onPaste={onPaste}
            float={float}
            {...props}
            type={showPassword ? InputTypes.Text : props.type}
            step={step}
            id={id}
          />

          {submitButton && <div className="absolute">{submitButton}</div>}

          {icon && handleIconClick && (
            <div
              className="absolute right-[12px] top-[50%] translate-y-[-50%] transform cursor-pointer"
              onClick={handleIconClick}
              {...iconProps}
            >
              {icon}
            </div>
          )}

          {props.type === 'password' && (
            <div
              className="absolute right-0 top-[15px] mr-[12px] cursor-pointer rounded-full stroke-[#292D32]"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <EyeIcon className="stroke-inherit" width={16} height={16} />
              ) : (
                <EyeSlashIcon
                  className="stroke-inherit"
                  width={16}
                  height={16}
                />
              )}
            </div>
          )}
        </div>
        {errorMessage && (
          <span
            className={`error-message text-red-medium ${invalid ? 'flex' : ''}`}
          >
            <Icon
              className="mr-[8px] shrink-0 text-micro2"
              iconId={IconIds.Danger}
              width="16"
              height="16"
            />
            {errorMessage}
          </span>
        )}
      </Label>
      {autocompleteOptions && (
        <DropdownMenu
          className={`absolute ${
            autocompleteOptions.length > 0 ? '' : 'hidden'
          } left-0 right-0 top-full translate-y-[4px]`}
          value={[]}
          options={autocompleteOptions}
          onChange={(val) => {
            setAutocompleteOptions([]);
            onChange(val[0]);
          }}
        />
      )}
    </section>
  );
};

export default FormInput;
